import './css/index.css';
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import SideNav from './components/SideNav'
import Comfort from './pages/Comfort'
import Energy from './pages/Energy'
import Devices from './pages/Devices'
import Scheduler from './pages/Scheduler'
import Settings from './pages/Settings'
import Resources from './pages/Resources'
import LobbyKiosk from './pages/LobbyKiosk'
import Loading from './components/Loading'
import Notifications from './pages/Notifications';

import {
  saveCampuses,
  setCurrentCampus,
  setCurrentBuilding,
  setCurrentZone,
  setCurrentGX,
  setCurrentGXData,
  setCurrentDeviceData,
  setCurrentZoneData,
  updateCurrentCampusData,
  setUserData
} from './actions'
import authentication from './utilities/Authentication'
import AuthenticatedComponent from './utilities/AuthenticatedComponent'
import configuration from './config'

const App = ({
  saveCampuses,
  setCurrentCampus,
  setCurrentBuilding,
  setCurrentZone,
  setCurrentGX,
  setCurrentGXData,
  setCurrentDeviceData,
  setCurrentZoneData,
  updateCurrentCampusData,
  setUserData,
  currentCampusId,
  currentBuildingId,
  currentZoneId,
  currentZoneData,
  currentGXId,
  currentCampusData,
  currentGXData,
  currentDeviceData
}) => {
  const authenticatedComponent = new AuthenticatedComponent
  const hasSchedule = sessionStorage.scheduleId
  const hasFilter = sessionStorage.pieFilter !== undefined
  let pieFilter = hasFilter && JSON.parse(sessionStorage.pieFilter).name

  if (pieFilter) {
    pieFilter = pieFilter[0].toLowerCase() + pieFilter.slice(1)
    pieFilter = pieFilter.replace(/ /g, '')
  }

  let currentUrl = window.location.pathname
  let pageParam = window.location.pathname.split('/')[1]
  let campusParam = window.location.pathname.split('/')[2]
  let buildingParam = window.location.pathname.split('/')[3]
  let zoneParam = window.location.pathname.split('/')[4]
  let gxParam = window.location.pathname.split('/')[3]

  const [campusesLoaded, setCampusLoaded] = useState(false)
  const [activePage, setActivePage] = useState('')
  const [showMobile, setShowMobile] = useState(window.innerWidth < 768 || window.screen.width < 768)
  const [currentWindowSize, setCurrentWindowSize] = useState(window.innerWidth)
  const [processing, setProcessing] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [showLoading, setShowLoading] = useState(false)
  const [selectedModal, setSelectedModal] = useState(null)
  const [currentScheduleId, setCurrentScheduleId] = useState(hasSchedule ? hasSchedule : null)
  // const [activeFilter, setActiveFilter] = useState(hasFilter ? [pieFilter] : [])
  const [hasSingleGX, setHasSingleGX] = useState(false)
  const [currentScheduleData, setCurrentScheduleData] = useState([])

  useEffect(() => {
    setShowMobile(window.innerWidth < 768 || window.screen.width < 768)
    handleResize()
    setActivePage(window.location.pathname.split('/')[1])

    sessionStorage.removeItem('scheduleId')

    if (pageParam !== '') {
      if (campusParam) {
        setCurrentCampus(campusParam)
        authenticatedComponent.updateCampusData(campusParam, updateCampusCallback)
      }

      //if tab is comfort
      if (pageParam === 'comfort') {
        //if url has campus, set campus
        if (campusParam) {
          setCurrentGX(null)
        }

        //if url has building, set current building
        if (buildingParam && zoneParam) {
          setCurrentBuilding(buildingParam)
          setCurrentZone(zoneParam)
        } else {
          setCurrentBuilding(null)
          setCurrentZone(null)
        }
      }

      //if tab is energy
      if (pageParam === 'energy') {
        //if url has campus, set campus
        if (campusParam) {
          setCurrentBuilding(null)
          setCurrentZone(null)
        }
        // if url has gx, set current gx
        if (gxParam !== undefined) {
          setCurrentGX(gxParam)
        }
      }
    }

    window.addEventListener('focus', validateAuthentication)
    window.addEventListener('resize', handleResize)
    window.addEventListener('popstate', onBackButtonEvent)
    
    return () => {
      window.removeEventListener('focus', validateAuthentication)
      window.removeEventListener('resize', handleResize)
      window.addEventListener('popstate', onBackButtonEvent)
    }
  }, [])

  useEffect(() => {
    if (authentication.isLoggedIn() || !currentUrl.includes('lobbyKiosk')) {
      authentication.handleAuthentication(postAuthentication)
    }
  }, [currentUrl])

  const validateAuthentication = () => {
    authentication.checkExpiration()
  }

  const setParams = (newUrl) => {
    pageParam = newUrl.split('/')[1]
    campusParam = newUrl.split('/')[2]
    buildingParam = newUrl.split('/')[3]
    zoneParam = newUrl.split('/')[4]
    gxParam = newUrl.split('/')[3]
    setActivePage(newUrl.split('/')[1])
  }

  const onBackButtonEvent = (e) => {
    e.preventDefault()
    let currentLocation = window.location.pathname
    
    window.location.replace(currentLocation)
  }

  const handleResize = () => {
    if (showMobile !== (window.innerWidth < 768 || window.screen.width < 768)) {
      window.location.replace(window.location.pathname)
    }

    setShowMobile(window.innerWidth < 768 || window.screen.width < 768)
    setCurrentWindowSize(window.innerWidth)
  }

  const updateCampusCallback = (data) => {
    updateCurrentCampusData(data)
  }

  const updateGXCallback = (energyData) => {
    setHasSingleGX(energyData.gridInterconnects.length === 1)
    setCurrentGXData(energyData)
  }

  const deviceCallback = (deviceData) => {
    setCurrentDeviceData(deviceData)
  }

  const zoneSummaryCallback = (zoneData) => {
    setCurrentZoneData(zoneData)
    if (currentZoneId !== zoneData.thermalZoneEntityKey) {
      setCurrentZone(zoneData.thermalZoneEntityKey)
    }
  }

  const allScheduleCallback = (data) => {
    setCurrentScheduleData(data)
  }

  const onBuildingChange = (buildingId) => {
    if (currentBuildingId !== buildingId) {
      setCurrentBuilding(buildingId)
    }
  }

  const onZoneChange = (zoneId) => {
    if (currentZoneId !== zoneId) {
      setCurrentZone(zoneId)
    }
  }

  const onGXChange = (gxId) => {
    if (currentGXId !== gxId) {
      setCurrentGX(gxId)
    }
  }

  const openScheduleModal = (selectModal, scheduleId) => {
    setSelectedModal(selectModal)
    setCurrentScheduleId(scheduleId)
  }

  function getResponse(response) {
    if (!response.ok) {
      if (response.status === 401) {
        authentication.logout()
        window.location.replace(authentication.logoutLink)
      }
      
      throw new Error('Not Accessible')
    }
    return response.json()
  }

  function processCampusJSON(json) {
    let campuses = []
    if (json) {
      campuses = json
    }

    saveCampuses(campuses)

    if (pageParam === "comfort") {
      if (!campusParam) {
        setCurrentCampus(campuses[0].campusEntityKey)
        setParams(`/comfort/${campuses[0].campusEntityKey}`)
        authenticatedComponent.updateCampusData(campuses[0].campusEntityKey, updateCampusCallback)
        window.location.replace(`/comfort/${campuses[0].campusEntityKey}`)
      } else {
        if (!zoneParam && buildingParam) {
          setParams(`/comfort/${campuses[0].campusEntityKey}`)
          setCurrentBuilding(null)
          window.location.replace(`/comfort/${campuses[0].campusEntityKey}`)
        }
      }
    } else if (pageParam === "energy") {
      authenticatedComponent.updateGXData(campusParam, updateGXCallback)
    } else if (pageParam === "lobbyKiosk") {
      authenticatedComponent.fetchKioskData(gxParam, setCurrentGXData)
    } else if (pageParam === "schedules") {
      authenticatedComponent.updateCampusData(currentCampusId, updateCampusCallback)
    } else if (window.location.pathname !== currentUrl) {
      window.location.replace(currentUrl)
    } else if (pageParam === "") {
      setCurrentCampus(campuses[0].campusEntityKey)
      setParams(`/comfort/${campuses[0].campusEntityKey}`)
      authenticatedComponent.updateCampusData(campuses[0].campusEntityKey, updateCampusCallback)
      window.location.replace(`/comfort/${campuses[0].campusEntityKey}`)
    }

    if (showMobile) {
      if (campusParam === undefined || campusParam === "" || campusParam === null) {
        setCurrentBuilding(campuses[0].buildings[0].buildingEntityKey)
      } else {
        campuses.filter((campus) => {
          if (campus.campusEntityKey === campusParam) {
            setCurrentBuilding(campus.buildings[0].buildingEntityKey)
          }
        })          
      }
    }
    
    setCampusLoaded(true)
    setShowLoading(false)
  }

  function loadCampuses() {
    fetch(configuration['backend_host'] + '/ahiapi/campuses', 
      { headers: authenticatedComponent.generateAuthenticatedHeader() }
    )
    .then(getResponse)
    .then(processCampusJSON)

    .catch(error => {
      saveCampuses([])
      console.error(error)

      setShowLoading(true)
      
      setTimeout(() => {
        loadCampuses()
      }, 30000)
    })
  }

  const postAuthentication = () => {
    //if stored URL, setParams
    for (var i = 0; i < sessionStorage.length; i++) {
      var value = sessionStorage.getItem(sessionStorage.key(i))

      if (sessionStorage.key(i).startsWith('state-') && value !== '/') {
        currentUrl = value
        setParams(value)

        sessionStorage.removeItem(sessionStorage.key(i).startsWith('state-'))
      }
    }

    loadCampuses()

    setShowLoading(false)
    processProfileData()
  }

  const processProfileData = () => {
    let userData = {
      first_name: '',
      last_name: '',
      email: ''
    }

    function getUserData() {
      fetch(configuration['backend_host'] + '/prefs/' + authentication.getUserId(),
        {
          headers: authenticatedComponent.generateAuthenticatedHeader()
        }
      )

      .then(getResponse)

      .then(data => {
        userData.first_name = data.prefs.first_name
        userData.last_name = data.prefs.last_name
        userData.email =  data.prefs.email
        setUserData(userData)

        sessionStorage.clear()
      })

      .catch(error => {
        saveCampuses([])
        console.error(error)

        setShowLoading(true)
        
        setTimeout(() => {
          getUserData()
        }, 30000)
      })
    }

    setShowLoading(false)
    getUserData()
  }

  if (authentication.isLoggedIn()) {
    if (campusesLoaded) {
      return (
        <div className="App">
          {showLoading && <Loading message="Unable to connect, but we're trying again." />}
          
          <BrowserRouter>
            {!currentUrl.includes('lobbyKiosk') && 
              (<>
                <SideNav 
                  showMobile={showMobile} 
                  activePage={activePage.toLowerCase()}
                  setActivePage={setActivePage}
                  currentWindowSize={currentWindowSize}
                  updateCampusData={() => authenticatedComponent.updateCampusData(currentCampusId, updateCampusCallback)}
                />
              </>)
            }
            
            <Routes>
              <Route 
                path="/"
                element={
                  <Comfort 
                    currentCampusId={currentCampusId}
                    currentCampusData={currentCampusData}
                    updateCampusCallback={updateCampusCallback}
                    currentBuildingId={currentBuildingId}
                    currentZoneId={currentZoneId}
                    currentZoneData={currentZoneData}
                    zoneSummaryCallback={zoneSummaryCallback}
                    onBuildingChange={onBuildingChange}
                    onGXChange={onGXChange}
                    onZoneChange={onZoneChange}
                    showMobile={showMobile}
                    openScheduleModal={openScheduleModal}
                    allScheduleCallback={allScheduleCallback}
                    currentScheduleData={currentScheduleData}
                    activePage={activePage.toLowerCase()}
                    processing={processing}
                    setProcessing={setProcessing}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                  />
                } 
              />
              <Route 
                path="/comfort/:campusId?/:buildingId?/:zoneId?"
                element={
                  <Comfort 
                    currentCampusId={currentCampusId}
                    currentCampusData={currentCampusData}
                    updateCampusCallback={updateCampusCallback}
                    currentBuildingId={currentBuildingId}
                    currentZoneId={currentZoneId}
                    currentZoneData={currentZoneData}
                    zoneSummaryCallback={zoneSummaryCallback}
                    onBuildingChange={onBuildingChange}
                    onGXChange={onGXChange}
                    onZoneChange={onZoneChange}
                    showMobile={showMobile}
                    openScheduleModal={openScheduleModal}
                    allScheduleCallback={allScheduleCallback}
                    currentScheduleData={currentScheduleData}
                    activePage={activePage.toLowerCase()}
                    processing={processing}
                    setProcessing={setProcessing}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                  />
                } 
              />
              <Route 
                path="/energy/:campusId?/:gxId?" 
                element={
                  <Energy 
                    currentCampusId={currentCampusId}
                    currentCampusData={currentCampusData}
                    currentBuildingId={currentBuildingId}
                    currentGXId={currentGXId}
                    hasSingleGX={hasSingleGX}
                    currentGXData={currentGXData}
                    onBuildingChange={onBuildingChange}
                    onGXChange={onGXChange}
                    onZoneChange={onZoneChange}
                    updateGXData={() => authenticatedComponent.updateGXData(currentCampusId, updateGXCallback)}
                    showMobile={showMobile}
                    activePage={activePage.toLowerCase()}
                  />
                } 
              />
              <Route 
                path="/devices/:campusId?/*" 
                element={
                  <Devices 
                    currentCampusId={currentCampusId}
                    currentCampusData={currentCampusData}
                    currentDeviceData={currentDeviceData}
                    updateDeviceData={() => authenticatedComponent.getDeviceData(currentCampusId, deviceCallback)}
                    deviceCallback={deviceCallback}
                    showMobile={showMobile}
                    processing={processing}
                    setProcessing={setProcessing}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                  />
                } 
              />
              <Route 
                path="/scheduler/:campusId?/*" 
                element={
                  <Scheduler 
                    currentCampusData={currentCampusData}
                    currentCampusId={currentCampusId}
                    openScheduleModal={openScheduleModal}
                    selectedModal={selectedModal}
                    setSelectedModal={setSelectedModal}
                    currentScheduleId={currentScheduleId}
                    setCurrentScheduleId={setCurrentScheduleId}
                    allScheduleCallback={allScheduleCallback}
                    currentScheduleData={currentScheduleData}
                    showMobile={showMobile}
                    processing={processing}
                    setProcessing={setProcessing}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                  />
                } 
              />
              <Route 
                path="/resources/:campusId?" 
                element={
                  <Resources
                    showMobile={showMobile}
                  />
                  } 
              />
              <Route 
                path="/settings/:campusId?" 
                element={
                  <Settings
                    showMobile={showMobile}
                  />
                  } 
              />
              <Route 
                path="/notifications/:campusId?" 
                element={
                  <Notifications
                    showMobile={showMobile}
                  />
                  } 
              />
              <Route 
                path="/lobbyKiosk/:campusId?/:gxId?" 
                element={
                  <LobbyKiosk 
                    currentCampusData={currentCampusData}
                    currentCampusId={currentCampusId}
                    currentGXId={currentGXId}
                    currentGXData={currentGXData}
                    updateGXData={() => authenticatedComponent.fetchKioskData(gxParam, setCurrentGXData)}
                  />
                } 
              />
            </Routes>
          </BrowserRouter>
        </div>
      )
    } else {
      return <div><Loading message="Loading..." /></div>
    }
  } else {
    if (currentUrl.includes('lobbyKiosk')) {
      return (
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route 
                path="/lobbyKiosk/:campusId?/:gxId?" 
                element={
                  <LobbyKiosk 
                    currentCampusData={currentCampusData}
                    currentCampusId={currentCampusId}
                    currentGXId={currentGXId}
                    currentGXData={currentGXData}
                    updateGXData={() => authenticatedComponent.fetchKioskData(gxParam, setCurrentGXData)}
                  />
                } 
              />
            </Routes>
          </BrowserRouter>
        </div>
      )
    }
  } 
}

const mapStateToProps = (state) => {
  return {
    campuses: state.campuses,
    currentCampusId: state.currentCampusId,
    currentBuildingId: state.currentBuildingId,
    currentZoneId: state.currentZoneId,
    currentGXId: state.currentGXId,
    currentGXData: state.currentGXData,
    currentDeviceData: state.currentDeviceData,
    currentCampusData: state.currentCampusData,
    currentZoneData: state.currentZoneData,
    currentUserData: state.currentUserData
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    saveCampuses: (campusesData) => {
      dispatch(saveCampuses(campusesData))
    },
    setCurrentCampus: (campusId) => {
      dispatch(setCurrentCampus(campusId))
    },
    setCurrentBuilding: (buildingId) => {
      dispatch(setCurrentBuilding(buildingId))
    },
    setCurrentZone: (zoneId) => {
      dispatch(setCurrentZone(zoneId))
    },
    setCurrentGX: (gxId) => {
      dispatch(setCurrentGX(gxId))
    },
    setCurrentGXData: (gxData) => {
      dispatch(setCurrentGXData(gxData))
    },
    setCurrentDeviceData: (deviceData) => {
      dispatch(setCurrentDeviceData(deviceData))
    },
    updateCurrentCampusData: (campusData) => {
      dispatch(updateCurrentCampusData(campusData))
    },
    setCurrentZoneData: (zoneData) => {
      dispatch(setCurrentZoneData(zoneData))
    },
    setUserData: (userData) => {
      dispatch(setUserData(userData))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)